import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w08GK07Diaetetik = () => (
  <Layout>
    <SEO title="w08GK07Diaetetik" />

    <h1 id="ueberschrift">Gemeinsam Kochen</h1>
    <h2>die chinesische Diätetik &emsp;  ©&nbsp;1991</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w08GK07Diaetetik&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Immer wieder hört man von den Wunder-Wirkungen der traditionellen chinesischen Medizin (TCM). Auch "einfache
        Essensregeln" sollen helfen: egal ob man zu- oder abnehmen, das Sodbrennen oder Blähungen loswerden, oder-oder-oder
        möchte.
        <br />
        Was steckt wirklich dahinter? Ist die Theorie wirklich umsetzbar?
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, in einem besseren Einklang mit Ihrem Körper zu leben.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w08GK07Diaetetik
